


















































import { Component } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { dispatchGetRequisitionsForReviewer, dispatchGetRequisitionsForReviewerById } from '@/store/crud/actions';


@Component({})
export default class ReviewerResults extends AppComponent {
  public headers = [
    {
      text: 'Requisition ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Sex',
      sortable: true,
      value: 'patient.biologicalSex',
      align: 'left',
    },
    {
      text: 'Age',
      sortable: true,
      value: 'patient.dob',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
    },
  ];

  public search =  '';



  public created() {
    this.fetchData();
  }

  public async fetchData() {
    this.setAppLoading(true);
    await dispatchGetRequisitionsForReviewer(this.$store);
    this.setAppLoading(false);
  }
  /**
   * Gets the date of birth based on the patient data and
   * current user privileges
   */
  public getDateOfBirth(patient) {
    if (patient && patient.dob) {
        const age = new Date().getFullYear() - new Date(patient.dob).getFullYear();
        return age + ' years old';
    } else {
      return 'N/A';
    }
  }

}
